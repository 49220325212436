// COLORS
$ds-black: #292929;
$ds-black-500: rgba($ds-black, 0.5);
$ds-brown: #3E3939;
$ds-orange: #FF7517;
$ds-white: #F6F4F4;
$ds-white-700: rgba($ds-white, 0.7);
$ds-darkorange: #DB6414;

// FONT SIZE
$font-size-7: 0.75rem !default;
$font-size-6: 0.875rem !default;
$font-size-5: 1rem !default;
$font-size-4: 1.125rem !default;
$font-size-3: 1.25rem !default;
$font-size-2: 1.5rem !default;
$font-size-1: 1.625rem !default;
$font-size-0: 3rem !default;