@import "../../assets/scss/main";

html {
  overflow: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.homepage {
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  position: relative;
}

section {
  height: 100vh;
  scroll-snap-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.background-video {
  width: 100%;
  height: 100%;
  top: 0;
  padding: unset;
  position: fixed;
  object-fit: cover;
  z-index: -1;
}

.banner__content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  height: 100vh;
  margin-left: 6rem;
  padding-top: 6rem;
}

.banner__title {
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', serif;
  font-style: normal;
  font-size: 6rem;
  line-height: 7.88rem;
  color: $ds-white;
  margin-bottom: 3rem;
  padding-top: 4rem;
}

.banner__title::after {
  content: " DUCKLINGS STUDIO";
  color: $ds-orange;
}

.game__main-description {
  padding-bottom: 2rem;
}

.link_btn {
  margin-bottom: 2rem;
  align-self: flex-end;
}

.get_btn,
.banner__btn {
  text-align: center;
  font-family: 'Roboto', serif;
  font-style: normal;
  background: $ds-darkorange;
  border: 0.065rem solid $ds-darkorange;
  color: $ds-white;
  font-size: $font-size-2;
  width: 12rem;
  padding: 1rem 0;
  margin-top: 1rem;
  border-radius: 0.4rem;
}

.get_btn:hover,
.banner__btn:hover {
  background: $ds-orange;
  border: 0.065rem solid $ds-orange;
}

.games {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.games-section {
  background-color: $ds-black-500;
}

.games__title {
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 500;
  font-size: $font-size-0;
  line-height: 3.5rem;
  color: $ds-white;
  margin: 3rem 0 1.5rem 0;
}

.game {
  background: $ds-black;
  border-radius: 0.75rem;
  width: 75vw;
}

.game__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 3rem 3rem 0;
  justify-content: space-between;
}

.game__name {
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 2.5rem;
  line-height: 2.38rem;
  margin-bottom: 0.875rem;
  color: $ds-white;
}

.game__about {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

.game__platforms {
  margin-left: 3rem;
}

.game__description,
.game__date-text,
.game__platforms-text {
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 400;
  font-size: $font-size-3;
  line-height: 1.44rem;
  color: $ds-white-700;
}

.game__platforms {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.game__icon {
  width: 3rem;
}

.game__date {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.game__btn {
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.375rem;
  background: none;
  border: none;
  padding: 1.5rem;
  color: $ds-white;
}

.pc-footer {
  display: block;
}

.phone-footer {
  display: none;
}

@media screen and (min-width: 1200px) {
  .game {
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
  }

  .game__img {
    max-width: 25rem;
    border-radius: 0.75rem 0 0 0.75rem;
  }
}

@media screen and (max-width: 1199px),
       screen and (max-height: 700px) {
  .banner__content img {
    width: 192px;
  }

  .banner__content {
    margin-left: 4rem;
    padding-top: 4rem;
  }

  .banner__title {
    font-size: 4rem;
    line-height: 6rem;
    padding-top: 3rem;
  }

  .pc-footer {
    display: none;
  }

  .phone-footer {
    display: block;
  }

  .footer,
  .info {
    height: 100vh;
  }

  .game {
    display: flex;
    flex-direction: column;
  }

  .game__img {
    height: 20vh;
    object-fit: cover;
    border-radius: 0.75rem 0.75rem 0 0;
  }

  .game__description {
    font-size: $font-size-4;
  }

  .game__date-text,
  .game__platforms-text {
    font-size: $font-size-4;
  }

  .get_btn {
    width: 10rem;
    font-size: $font-size-3;
    margin-top: 0.75rem;
  }
}

@media screen and (max-width: 600px),
       screen and (max-height: 540px) {

  .games__title {
    margin: 1.5rem 0 1rem 0;
  }
  
  .game__description {
    display: none;
  }
  .game__main-description {
    display: block !important;
    padding-bottom: 0;
  }
  
  .banner__title {
    font-size: 3.25rem;
    line-height: 5rem;
    padding-top: 2rem;
    margin-bottom: 2rem;
  }

  .banner__content {
    margin-left: 2rem;
    padding-top: 2rem;
  }

  .banner__content img {
    width: 128px;
  }

  .game__about {
    display: flex;
    flex-direction: column;
    padding-bottom: 0.375rem;
  }

  .game__platforms {
    margin-left: 0;
  }

  .game__description {
    font-size: $font-size-5;
  }

  .game__date-text,
  .game__platforms-text {
    font-size: $font-size-5;
  }

  .get_btn {
    font-size: $font-size-5;
    width: 7rem;
    padding: 0.75rem 0.75rem;
    border-radius: 0.4rem;
    margin: 0.75rem 0;
  }
}
